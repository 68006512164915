/* eslint-disable no-restricted-imports */
import {
  Hide as CHide,
  HideProps,
  Show as CShow,
  ShowProps,
  UseBreakpointOptions,
  useBreakpointValue as useCBreakpointValue,
  useMediaQuery as useCMediaQuery,
  UseMediaQueryOptions,
} from '@chakra-ui/react';

export const Hide = (p: Omit<HideProps, 'ssr'>) => <CHide ssr={false} {...p} />;
export const Show = (p: Omit<ShowProps, 'ssr'>) => <CShow ssr={false} {...p} />;

export const useMediaQuery = (
  query: string | string[],
  options?: Omit<UseMediaQueryOptions, 'ssr'>,
) => useCMediaQuery(query, { ssr: false, ...options });

export const useBreakpointValue = <T,>(
  v: Partial<Record<string, T>> | Array<T | null>,
  o?: Omit<UseBreakpointOptions, 'ssr'>,
) => useCBreakpointValue<T>(v, { ssr: false, ...o });
